export default [
  'tania@foodforjoe.es',
  'nuria@foodforjoe.es',
  'daniel.garrido@foodforjoe.es',
  'albane@foodforjoe.es',
  'diego@foodforjoe.es',
  'luis.felipe@foodforjoe.es',
  'carla@foodforjoe.es',
  'noelia@foodforjoe.es',
]
