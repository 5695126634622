import {useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import {PaymentMethodEnum} from '../models/DistributorModel'
import { getAllDistributorFiles } from '../services/DistributorCRUD'
import { toast } from 'react-toastify'

export default function DistributorList({
  children,
  distributors = [],
  loading = false,
  onAddDistributor,
  onRemoveDistributor,
}) {
  let history = useHistory()
  const intl = useIntl()

  const onClick = (e, distributorId) => {
    const target = e.target
    if (target.tagName === 'BUTTON' || target.tagName === 'A') {
      return
    }
    history.push(`/distributor/${distributorId}/`)
  }

  const onRemove = (e, distributor) => {
    e.stopPropagation()
    onRemoveDistributor(distributor)
  }

  async function downloadDistributors() {
    try {
      const hasConfirmed = window.confirm(intl.formatMessage({id: 'distributors.downloadConfirmation'}))
        
      if (hasConfirmed) {
        await getAllDistributorFiles();
        toast.success(intl.formatMessage({id: 'distributors.downloadSuccess'}))
      } 
    } catch (error) {
      toast.error(intl.formatMessage({id: 'distributors.downloadError'}))
    }
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-start mb-8'>
        <h2 className=''>{intl.formatMessage({id: 'distributors.listTitle'})}</h2>
        <div className='d-flex gap-4'>
          <button onClick={downloadDistributors} className='btn btn-secondary'>
            <span className='fa fa-download me-2' />
            {intl.formatMessage({id: 'distributors.download'})}
          </button>
          <button onClick={onAddDistributor} className='btn btn-primary'>
            <span className='fa fa-plus me-2' />
            {intl.formatMessage({id: 'distributors.addNewDistributor'})}
          </button>
        </div>
      </div>
      <div className='row mb-8'>{children}</div>
      {loading && <div className='spinner-border text-primary' role='status' />}
      {!loading && distributors.length === 0 && (
        <p>{intl.formatMessage({id: 'distributors.noDistributors'})}</p>
      )}
      {!loading && distributors.length > 0 && (
        <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
          <thead className='table-header sticky-top bg-white'>
            <tr className=''>
              <th className='ps-2'>{intl.formatMessage({id: 'distributors.id'})}</th>
              <th>{intl.formatMessage({id: 'distributors.name'})}</th>
              <th>{intl.formatMessage({id: 'distributors.vatNumber'})}</th>
              <th>{intl.formatMessage({id: 'distributors.email'})}</th>
              <th>{intl.formatMessage({id: 'distributors.phone'})}</th>
              <th>{intl.formatMessage({id: 'distributors.paymentMethod'})}</th>
              <th>{intl.formatMessage({id: 'distributors.maxPaymentDays'})}</th>
              <th>{intl.formatMessage({id: 'distributors.view'})}</th>
              <th className='pe-2'>{intl.formatMessage({id: 'distributors.remove'})}</th>
            </tr>
          </thead>
          <tbody className='text-left'>
            {distributors
              ?.sort((a, b) => b.id - a.id)
              ?.map((distributor, index) => (
                <tr
                  className='cursor-pointer'
                  key={index}
                  onClick={(e) => onClick(e, distributor.id)}
                >
                  <td className='ps-2'>{distributor.id}</td>
                  <td className='d-flex flex-wrap align-items-start gap-1 flex-column'>
                    {distributor.name}
                    {distributor?.is_non_payer ? (
                      <span className='badge badge-warning text-dark'>
                        {intl.formatMessage({id: 'distributors.nonPayer'})}
                      </span>
                    ) : (<span className='badge badge-light-success'>
                      {intl.formatMessage({id: 'distributors.notDefaulted'})}
                    </span>)}
                  </td>
                  <td>{distributor.vat_number}</td>
                  <td>{distributor.email || '-'}</td>
                  <td>{distributor.phone || '-'}</td>
                  <td>
                    {distributor?.payment_method === PaymentMethodEnum.WIRE_TRANSFER &&
                      intl.formatMessage({id: 'distributors.wireTransfer'})}

                    {distributor?.payment_method === PaymentMethodEnum.BANK_DRAFT &&
                      intl.formatMessage({id: 'distributors.bankDraft'})}
                  </td>
                  <td>{distributor?.max_payment_days}</td>
                  <td>
                    <Link
                      to={`/distributor/${distributor.id}/`}
                      className='btn btn-sm btn-tertiary fa fa-eye text-primary py-0'
                    />
                  </td>
                  <td className='pe-2'>
                    <button
                      onClick={(e) => onRemove(e, distributor)}
                      className='btn btn-sm btn-tertiary fa fa-trash text-danger py-0'
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  )
}
