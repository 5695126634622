export enum LeadOrderStateEnum {
    PROPOSAL = 'proposal',
    CONFIRMED = 'confirmed',
    LOCKED = 'locked',
    PROCESSING = 'processing',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    SHIPPED = 'shipped',
    RETURNED = 'returned',
    UNDELIVERED = 'undelivered',
}
  
export interface LeadOrderModel  {
    code?: string;
    comments?: string;
    created_at?: string;
    distributor: number;
    id?: number;
    promise_date: string;
    shipping_address: number;
    state?: LeadOrderStateEnum;
    tracking_link?: string;
    kind: 'dog' | 'full';
}
