import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import {Dropdown} from 'react-bootstrap'

import { PlanModel } from '../models/PlanModel'
import { planStatusSelector } from '../redux/PlanRedux'
import AddProductModal from './AddProductModal'
import AddCustomConceptModal from './AddCustomConceptModal'
import { useState } from 'react'
import { actions } from '../redux/PlanRedux'
import { removeExtraBillableItem } from '../services/PlanCRUD'
import { toast } from 'react-toastify'

export default function ExtraProducts() {
    const intl = useIntl()
    const dispatch = useDispatch()
    const selectedPlan: PlanModel | undefined = useSelector(
        (state: RootState) => state.plan.selectedPlan,
        shallowEqual
    )
    const status: string = useSelector(planStatusSelector, shallowEqual)
    const isLoading = [
        'loading_selected_plan',
        'loading_recalculate_plan',
        'loading_plan_orders',
    ].includes(status)
    const [addProductModalVisible, setAddProductModalVisible] = useState(false)
    const [addExtraModalVisible, setAddExtraModalVisible] = useState(false)

    function onRemoveProduct(productVariantId: number) {
        dispatch(actions.removeProductFromSelectedPlan(productVariantId))
    }

    async function onRemoveConcept(productText: string) {
        try {
            const { data: updatedPlan } = await removeExtraBillableItem({
                planId: selectedPlan.id,
                productText,
            })
            dispatch(actions.setSelectedPlan(updatedPlan))
            toast.success(intl.formatMessage({ id: 'planDetail.conceptRemoved' }))
        } catch (error) {
            console.warn(error)
            toast.error(intl.formatMessage({ id: 'planDetail.errorRemovingConcept' }))
        }
    }

    return (<>
        <section className='card p-10'>
            <h1 className='mb-8'>{intl.formatMessage({ id: 'planDetail.extraProducts' })}</h1>
            {isLoading && (
                <div>
                    <div className='spinner-border text-primary' role='status' />
                </div>
            )}
            {!isLoading && !selectedPlan?.extra_products.length && (
                <p>{intl.formatMessage({ id: 'planDetail.noExtraProducts' })}</p>
            )}
            {!isLoading && selectedPlan?.extra_products.length > 0 && (
                <div className='overflow-scroll'>
                    <table className='table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                        <thead className='table-header'>
                            <tr className='border-0 fw-bold'>
                                <th className=''>{intl.formatMessage({ id: 'planDetail.productName' })}</th>
                                <th className='text-end'>{intl.formatMessage({ id: 'planDetail.units' })}</th>
                                <th className='text-end'>{intl.formatMessage({ id: 'planDetail.price' })}</th>
                                <th className='text-end'>{intl.formatMessage({ id: 'planDetail.remove' })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedPlan?.extra_products.map(({
                                product_text,
                                fixed_price,
                                product_variant,
                                units,
                            }) => (
                                <tr key={product_variant?.id} className={''}>
                                    <td>
                                        {
                                            product_text ||
                                            product_variant?.name
                                        }
                                    </td>
                                    <td className='text-end'>{units}</td>
                                    <td className='text-end'>
                                        {
                                            product_variant?.price > -1 ? intl.formatNumber(
                                                product_variant?.price * units,
                                                { style: 'currency', currency: 'EUR' }
                                            ) : intl.formatNumber(
                                                fixed_price * units,
                                                { style: 'currency', currency: 'EUR' }
                                            )
                                        }
                                    </td>
                                    <td className='text-end'>
                                        <button
                                            onClick={() => 
                                                product_variant?.id?
                                                    onRemoveProduct(product_variant?.id)
                                                    : onRemoveConcept(product_text)
                                                }
                                            type='button'
                                            className='btn fa fa-trash text-muted'
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <Dropdown>
                <Dropdown.Toggle 
                    variant="primary" 
                    id="dropdown-basic" 
                    disabled={isLoading}
                    className='ms-auto d-block'
                   >
                    <span className='fa fa-plus me-4' />
                    {intl.formatMessage({ id: 'planDetail.addExtra' })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setAddProductModalVisible(true)} className='py-4'>
                        {intl.formatMessage({ id: 'planDetail.addExtraProduct' })}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setAddExtraModalVisible(true)} className='py-4'>
                        {intl.formatMessage({ id: 'planDetail.addCustomConcept' })}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </section>
        <AddProductModal
            visible={addProductModalVisible}
            onClose={() => setAddProductModalVisible(false)}
        />
        <AddCustomConceptModal
            visible={addExtraModalVisible}
            onClose={() => setAddExtraModalVisible(false)}
        />
    </>
    )
}
