import axios from 'axios'

import {PlanModel} from '../models/PlanModel'
import {PlanListingModel} from '../models/PlanListingModel'
import {ShippingServiceModel} from '../models/ShippingServiceModel'
import { MealModel } from '../models/MealModel'
import { TaxModel } from '../../distributors'
const API_URL = process.env.REACT_APP_API_URL
const PLANS_PATH = '/api/plans/'

export function listPlans(userId: number): Promise<{data: Array<PlanListingModel>}> {
  const endpoint = `${API_URL}${PLANS_PATH}?u=${userId}`

  return axios.get(endpoint)
}

export function listShippingServices(): Promise<{data: Array<ShippingServiceModel>}> {
  const endpoint = `${API_URL}/api/shipping-service/`

  return axios.get(endpoint)
}
export function listPetPlans(petId: number): Promise<{data: Array<PlanListingModel>}> {
  const endpoint = `${API_URL}${PLANS_PATH}?pet=${petId}`

  return axios.get(endpoint)
}

export function getPlanById(id: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${id}/`

  return axios.get(endpoint)
}

export function updatePlan(planId: number, props: Partial<PlanModel>): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/`
  const {
    kind,
    period,
    combine,
    wallet_shipping_address,
    ko_reason,
    max_days_in_freezer,
    shipping_service,
    can_get_800gr_pouches,
    can_get_giant_pouches,
    allow_small_shipments,
  } = props
  const editableProps = {
    kind,
    period: period?.id,
    combine,
    wallet_shipping_address: wallet_shipping_address?.id,
    ko_reason,
    max_days_in_freezer,
    shipping_service: shipping_service?.id,
    can_get_800gr_pouches,
    can_get_giant_pouches,
    allow_small_shipments,
  }
  return axios.patch(endpoint, editableProps)
}

export function discardPlan(planId: number) {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/discard/`

  return axios.put(endpoint)
}

export function pausePlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/pause/`

  return axios.put(endpoint)
}

export function resumePlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/resume/`

  return axios.put(endpoint)
}

export function cancelPlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/cancel/`

  return axios.put(endpoint)
}

export function confirmPlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/confirm/`

  return axios.put(endpoint)
}

export function reactivatePlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/reactivate/`

  return axios.put(endpoint)
}

export function retryPayment(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/retry-payment/`

  return axios.put(endpoint)
}

export function refreshPromiseDate(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/refresh-promise-date/`

  return axios.put(endpoint)
}

export function recalculatePlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/recalculate/`

  return axios.put(endpoint)
}

export function refreshPrices(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/refresh-prices/`

  return axios.put(endpoint)
}

export function reproposePlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/repropose/`

  return axios.put(endpoint)
}

export function detainPlan(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/detain/`

  return axios.put(endpoint)
}

export function generateOrders(planId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/generate-orders/`

  return axios.post(endpoint)
}

export function createCustomMeal(
  planId: number,
  meal: number,
  meal_size: number,
  units: number
): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/custom-meal/`
  const data = {
    meal,
    meal_size,
    units,
  }

  return axios.post(endpoint, data)
}

export function updateCustomMeal(
  planId: number,
  meal: number,
  meal_custom: number,
  meal_size: number,
  units: number
): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/custom-meal/`
  const data = {
    meal_custom,
    meal,
    meal_size,
    units,
  }

  return axios.patch(endpoint, data)
}

export function deleteCustomMeal(planId: number, meal_custom: number) {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/custom-meal/`
  const data = {
    meal_custom,
  }

  return axios.delete(endpoint, {data})
}

export function addDiscount(
  planId: number,
  discountId: number,
  expiresAt?: string
): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/`
  const data = {
    add_discount: true,
    discount: discountId,
    expires_at: expiresAt, // date as string
  }

  return axios.patch(endpoint, data)
}

export function removeDiscount(planId: number, discountId: number): Promise<{data: PlanModel}> {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/`
  const data = {
    add_discount: false,
    subscription_discount: discountId,
  }

  return axios.patch(endpoint, data)
}

export function lockOrders({
  planId,
  discountCode,
  count,
}) {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/lock-next-n-orders/`
  const data = {
    discount_code: discountCode,
    n: count,
  }

  return axios.post(endpoint, data)
}

interface EditExtraProductProps {
  planId: number
  extraProducts: Array<{product_variant: number; units: number}>
}
export function editExtraProducts({
  planId,
  extraProducts = [],
}: EditExtraProductProps) {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/extra-product/`
  const data = extraProducts;

  return axios.post(endpoint, data)
}

export function removeProductFromPlan({
  planId,
  productVariantId,
}) {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/extra-product/`
  const data = {
    product_variant: productVariantId,
  }

  return axios.delete(endpoint, {data})
}

export function addExtraBillableItem({
  planId,
  productText,
  fixedPrice,
  fiscalPosition,
}): Promise<{data: PlanModel}>{
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/extra-billable-item/`;
  const data = [{
    product_text: productText,
    fixed_price: fixedPrice,
    fiscal_position: fiscalPosition,
  }];

  return axios.post(endpoint, data)
}

export function removeExtraBillableItem({planId, productText}) {
  const endpoint = `${API_URL}${PLANS_PATH}${planId}/extra-billable-item/`;
  const data = {
    product_text: productText,
  };

  return axios.delete(endpoint, {data})
}

export interface Meal {
  id: number;
  pet_kinds: Array<{
    id: number;
    name: string;
    code: string;
  }>;
  name: string;
}

export function listMeals(): Promise<{data: Array<Meal>}>{
  const endpoint = `${API_URL}/api/meals/`

  return axios.get(endpoint)
}

export interface Store {
  id: number;
  name: string;
  country: string;
}

export function listStores(): Promise<{data: Array<Store>}>{
  const endpoint = `${API_URL}/api/store/`

  return axios.get(endpoint)
}

export interface PlanCalculatorResponse {
  id: number;
  daily_calorie_intake: number;
  daily_protein_intake: number;
  meal_price_per_day_scheme: Array<{
    combine: boolean;
    meal_kind: {
      id: number;
      code: string;
    };
    period: {
      id: number;
      days: number;
    };
    meal_price_per_day: number;
  }>;
  meal_price_per_day: number;
  max_days_in_freezer: number;
  average_grams_per_day: number;
  avg_price_per_100gr: number;
  default_meal_price: number;
  trial_price: number;
  regular_price: number;
  default_meal_gross_margin: number;
}


export interface PlanCalculatorRequest {
  store: number;
  pet_kind: number;
  is_neutered: boolean;
  age_years: number;
  age_months: number;
  weight_in_kg: number;
  activity_level: number;
  body_condition: number;
  meals: number[];
  combine: boolean;
}
 

export function calculatePlan(request: PlanCalculatorRequest): Promise<{data: PlanCalculatorResponse}>{
  const endpoint = `${API_URL}/api/plans/create-calculated-plans/`;

  return axios.post(endpoint, request);
}

export interface ListTaxesProps {
  country: string;
}

export function listTaxes({
  country
}: ListTaxesProps) : Promise<{data: Array<TaxModel> }> {
  const endpoint = `${API_URL}/api/taxes/?country=${country}`;

  return axios.get(endpoint);
}

export interface PricingResponse {
  plans: {
    [planId: string]: {
      trial: {
        shipping_fee: number;
        total_price_inc_tax: number;
      },
      regular: {
        shipping_fee: number;
        total_price_inc_tax: number;
      },
    }
  },
  totals: {
    trial: {
      shipping_fee: number;
      total_price_inc_tax: number;
    },
    regular: {
      shipping_fee: number;
      total_price_inc_tax: number;
    },
  }
}

export function getAggregatePricing({
  planIds
}: {
  planIds: Array<number>
}): Promise<{ data: PricingResponse }> {
  const endpoint = `${API_URL}/api/plans/pricing/`;
  const body = { plans: planIds };

  return axios.post(endpoint, body);
}