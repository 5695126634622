/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import { listShippingServices } from '../../modules/plans'
import {PageTitle} from '../../../_metronic/layout/core'
import {   
  LeadOrderList,
  ModalLeadOrderForm,
  LeadOrderModel,
  LeadOrderStateEnum,
  createLeadOrder,
  listLeadOrders,
  BulkActionsEnum,
  updateLeadOrder,
  performBulkAction,
} from '../../modules/b2b-lead-orders'
import COUNTRIES from '../../constants/countries'
import COUNTRY_NAMES from '../../constants/countryNames'
import {LeadDistributorModel, listLeadDistributors} from '../../modules/lead-distributors'
import DateRangeSelector from '../../components/DateRangeSelector'

const ANY_DISTRIBUTOR = 'ANY_DISTRIBUTOR'
const ANY_STATE = 'ANY_STATE'
const ANY_SERVICE = 'ANY_SERVICE'
const EMPTY_ORDER = {
  shipping_fee: 0.0,
  promise_date: '',
  shipping_address: null,
  distributor: null,
  comments: '',
  kind: 'dog',
}

const B2BLeadOrdersPage: FC = () => {
  const intl = useIntl()
  const [visibleOrderModal, setVisibleOrderModal] = useState(false)
  const [order, setOrder] = useState<LeadOrderModel | undefined>(undefined)
  const [distributors, setDistributors] = useState([])
  const [orders, setOrders] = useState<Array<LeadOrderModel>>([])
  const [loading, setLoading] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState<LeadDistributorModel | null>(null)
  const [selectedState, setSelectedState] = useState<LeadOrderStateEnum | null>(LeadOrderStateEnum.PROPOSAL)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [country, setCountry] = useState<string | null>(null)
  const [code, setCode] = useState<string | null>(null)
  const [promiseDateRange, setPromiseDateRange] = useState<{ since: Date; until: Date }>({
    since: new Date(),
    until: new Date(),
  })
  const [selectedShippingService, setSelectedShippingService] = useState(null)
  const [shippingServices, setShippingServices] = useState([])

  async function fetchShippingServices() {
    try {
      const {data} = await listShippingServices()
      setShippingServices(data)
    } catch (error) {
      console.warn(error)
    }
  }

  async function fetchOrders(withLoader = true) {
    try {
      if (withLoader) {
        setLoading(true)
      }
      const since = promiseDateRange.since.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const until = promiseDateRange.until.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const {data} = await listLeadOrders({
        distributorId: selectedDistributor?.id, 
        selectedState, 
        page,
        country,
        code,
        shipping_service: selectedShippingService?.id,
        since,
        until,
      })
      setOrders(data.results)
      setCount(data.count)
    } catch (error) {
      console.warn(error)
    } finally {
      if (withLoader) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [selectedDistributor, selectedState, page, country, code, promiseDateRange, selectedShippingService])

  useEffect(() => {
    setPage(1)
    setCount(0)
  }, [selectedDistributor, selectedState, country, code, promiseDateRange, selectedShippingService])

  const fetchDistributors = async () => {
    try {
      const {data} = await listLeadDistributors()
      setDistributors(data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchDistributors()
    fetchShippingServices()
  }, [])

  function onCreateOrder() {
    setOrder(EMPTY_ORDER as LeadOrderModel)
    setVisibleOrderModal(true)
  }

  async function createNewOrder(leadOrderModel: LeadOrderModel) {
    try {
      setLoading(true)
      const {data: newlyCreatedOrder} = await createLeadOrder(leadOrderModel)
      setOrder(newlyCreatedOrder)
      toast.success(intl.formatMessage({id: 'b2bLeadOrders.createSuccess'}), {
        position: toast.POSITION.TOP_RIGHT,
      })
      setVisibleOrderModal(false)

      await fetchOrders()
    } catch (error) {
      toast.error(intl.formatMessage({id: 'b2bLeadOrders.createError'}))
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateExistingOrder(leadOrderModel: LeadOrderModel) {
    try {
      setLoading(true)
      await updateLeadOrder(leadOrderModel)
      toast.success(intl.formatMessage({id: 'b2bLeadOrders.updateSuccess'}), {
        position: toast.POSITION.TOP_RIGHT,
      })
      setVisibleOrderModal(false)

      await fetchOrders()
    } catch (error) {
      toast.error(intl.formatMessage({id: 'b2bLeadOrders.updateError'}))
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  function onSaveCreatedOrder(order: LeadOrderModel) {
    if (order?.id) {
      updateExistingOrder(order)
    } else {
      createNewOrder(order)
    }
    fetchOrders()
  }

  function onDistributorChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const distributorId = parseInt(event.target.value, 10)
    const distributor = distributors.find((d) => d.id === distributorId)
    setSelectedDistributor(distributor)
  }

  function onStateChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === ANY_STATE) {
      setSelectedState(null)
    } else {
      setSelectedState(event.target.value as LeadOrderStateEnum)
    }
  }

  function onEditOrder(order: LeadOrderModel) {
    setOrder(order)
    setVisibleOrderModal(true)
  }

  async function onPerformBulkAction(action: BulkActionsEnum, orders: number[]) {
    const since = promiseDateRange.since.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const until = promiseDateRange.until.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return performBulkAction({
      action,
      orders,
      distributor: selectedDistributor?.id || null,
      state: selectedState,
      shipping_service: selectedShippingService?.id || null,
      country,
      code,
      since,
      until,
    });  
  } 
  
  async function onEditComments(order: LeadOrderModel) {
    setOrder(order)
    setVisibleOrderModal(true)
  }

  async function onUpdateState(order: LeadOrderModel, state: LeadOrderStateEnum) {
    try {
      await updateLeadOrder({
        ...order,
        state,
      })
      fetchOrders(false)
      toast.success(intl.formatMessage({id: 'b2bOrders.updateStateSuccess'}))
    } catch (error) {
      toast.error(intl.formatMessage({id: 'b2bOrders.updateStateError'}))
      console.warn(error)
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'b2bLeadOrders.title'})}</PageTitle>
      <div className='row'>
        <div className='col-12'>
          <LeadOrderList
            page={page}
            count={count}
            onPageChange={setPage}
            displayBulkActions={true}
            orders={orders}
            loading={loading}
            onCreateOrder={onCreateOrder}
            onEditOrder={onEditOrder}
            onEditComments={onEditComments}
            onUpdateState={onUpdateState}
            onBulkGeneratePickingSheet={(orders) => onPerformBulkAction(BulkActionsEnum.GENERATE_PICKING_SHEET, orders)}
            onBulkPrepareOrders={(orders) => onPerformBulkAction(BulkActionsEnum.PREPARE_LEAD_ORDERS, orders)}
          >
            <div className='d-flex align-items-end justify-content-start flex-wrap'>
              <div className='form-group w-250px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({id: 'b2bLeadOrders.filterByDistributor'})}
                </label>
                <select
                  className='form-control form-select'
                  value={selectedDistributor?.id}
                  onChange={onDistributorChange}
                >
                  <option value={ANY_DISTRIBUTOR}>
                    {intl.formatMessage({id: 'b2bLeadOrders.anyDistributor'})}
                  </option>
                  {distributors.map((distributor) => (
                    <option key={distributor.id} value={distributor.id}>
                      {distributor.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({id: 'b2bOrders.filterByService'})}
                </label>
                <select
                  className='form-control form-select'
                  value={selectedShippingService?.id}
                  onChange={(e) => setSelectedShippingService(shippingServices.find((s) => s.id === parseInt(e.target.value, 10)))}
                >
                  <option value={ANY_SERVICE}>
                    {intl.formatMessage({id: 'b2bOrders.anyService'})}
                  </option>
                  {shippingServices.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({id: 'b2bOrders.filterByState'})}
                </label>
                <select
                  className='form-control form-select'
                  value={selectedState}
                  onChange={onStateChange}
                >
                  <option value={ANY_STATE}>
                    {intl.formatMessage({id: 'b2bOrders.anyState'})}
                  </option>
                  {Object.values(LeadOrderStateEnum).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByCountry' })}
                </label>
                <select
                  className='form-control form-select'
                  value={country}
                  onChange={(e) => setCountry(e.target.value || null)}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'b2bOrders.anyCountry' })}
                  </option>
                  { COUNTRIES.map((country) => (
                    <option key={country} value={country}>
                      {COUNTRY_NAMES[country]}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group w-180px d-inline-block me-4'>
                <label className='fw-bolder mb-1'>
                  {intl.formatMessage({ id: 'b2bOrders.filterByCode' })}
                </label>
                <input
                  type='text'
                  className='form-control'
                  placeholder={intl.formatMessage({ id: 'b2bOrders.codePlaceholder' })}
                  value={code || ''}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className='form-group w-180px d-inline-block mt-8'>
                <DateRangeSelector
                  label={intl.formatMessage({ id: 'operationOrders.filterByPromiseDate' })}
                  onSelected={(since, until) => setPromiseDateRange({ since, until })}
                  initialSince={new Date()}
                  initialUntil={new Date()}
                />
              </div>
            </div>
          </LeadOrderList>
        </div>
      </div>
      <ModalLeadOrderForm
        visible={visibleOrderModal}
        onCancel={() => setVisibleOrderModal(false)}
        onSaveOrder={onSaveCreatedOrder}
        order={order}
        distributors={distributors}
      />
    </>
  )
}

export {B2BLeadOrdersPage}
