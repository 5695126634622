import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import {toast} from 'react-toastify'

import {RootState} from '../../../../setup'
import * as planRedux from '../redux/PlanRedux'
import {PlanModel} from '../models/PlanModel'
import {GenericForm, FormField} from '../../../components/GenericForm'
import {PetModel, petsSelector} from '../../pets'
import {currentUserSelector} from '../../user-profile'
import {listShippingServices} from '../services/PlanCRUD'
import { authUserSelector } from '../../auth'

export default function PlanDetail() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const authUser = useSelector(authUserSelector)
  const selectedPlan: PlanModel | undefined = useSelector(planRedux.selectedPlanSelector)
  const pets: PetModel[] | undefined = useSelector(petsSelector, shallowEqual)
  const currentUser = useSelector(currentUserSelector, shallowEqual)
  const status: string = useSelector(planRedux.planStatusSelector, shallowEqual)
  const [editing, setEditing] = useState(false)
  const [shippingServices, setShippingServices] = useState([])
  const fields: Array<FormField<PlanModel>> = [
    {
      id: 'code',
      label: intl.formatMessage({id: 'planDetail.code'}),
      inputType: 'text',
      readonly: true,
    },
    {
      href: `/user/${currentUser?.id}/pet/${selectedPlan?.pet_id}`,
      id: 'pet_name',
      label: intl.formatMessage({id: 'planDetail.petName'}),
      inputType: 'text',
      readonly: true,
      getter: (aPlan: PlanModel) => pets?.find((pet) => pet.id === aPlan.pet_id)?.name || '',
    },
    {
      id: 'state',
      label: intl.formatMessage({id: 'planDetail.state'}),
      inputType: 'text',
      readonly: true,
    },
    {
      id: 'created_at',
      label: intl.formatMessage({id: 'planDetail.createdAt'}),
      inputType: 'date',
      readonly: true,
    },
    {
      id: 'meal_kind',
      label: intl.formatMessage({id: 'planDetail.mealKind'}),
      inputType: 'text',
      readonly: true,
      getter: (aPlan: PlanModel) => aPlan?.meal_kind?.name,
      setter: (aPlan: PlanModel, value: string) => {
        if (aPlan?.meal_kind) {
          aPlan.meal_kind.name = value
        }
        return aPlan
      },
    },
    {
      id: 'kind',
      label: intl.formatMessage({id: 'planDetail.kind'}),
      inputType: 'select',
      options: [
        {
          label: intl.formatMessage({id: 'planDetail.custom'}),
          value: 'custom',
        },
        {
          label: intl.formatMessage({id: 'planDetail.auto'}),
          value: 'automatic',
        },
      ],
    },
    {
      id: 'pricing_model',
      label: intl.formatMessage({id: 'planDetail.pricingModel'}),
      inputType: 'select',
      readonly: true,
      options: [
        {
          label: intl.formatMessage({id: 'plans.old'}),
          value: 'old',
        },
        {
          label: intl.formatMessage({id: 'plans.new'}),
          value: 'new-2023',
        },
        {
          label: intl.formatMessage({id: 'plans.2025'}),
          value: '2025',
        }
      ],
    },
    {
      id: 'period',
      label: intl.formatMessage({id: 'planDetail.period'}),
      inputType: 'select',
      getter: (state: PlanModel) => state.period?.id,
      setter: (state, value) =>
        ({
          ...state,
          period: {id: value},
        } as PlanModel),
      options: [
        {
          label: intl.formatMessage({id: 'planDetail.daysPeriod'}, {days: 14}),
          value: 1,
        },
        {
          label: intl.formatMessage({id: 'planDetail.daysPeriod'}, {days: 21}),
          value: 5,
        },
        {
          label: intl.formatMessage({id: 'planDetail.daysPeriod'}, {days: 28}),
          value: 2,
        },
        {
          label: intl.formatMessage({id: 'planDetail.daysPeriod'}, {days: 42}),
          value: 3,
        },
      ],
    },
    {
      id: 'combine',
      label: intl.formatMessage({id: 'planDetail.combine'}),
      inputType: 'checkbox',
    },
    {
      id: 'wallet_shipping_address',
      label: intl.formatMessage({id: 'planDetail.walletShippingAddress'}),
      inputType: 'select',
      options: currentUser?.shipping_addresses?.map((address) => ({
        label: address.street,
        value: address.id,
      })),
      getter: (aPlan: PlanModel) => aPlan.wallet_shipping_address?.id,
      setter: (aPlan: PlanModel, value: string) => {
        return {
          ...aPlan,
          wallet_shipping_address: currentUser?.shipping_addresses?.find(
            (address) => address.id === parseInt(value)
          ),
        }
      },
    },
    {
      id: 'max_days_in_freezer',
      label: intl.formatMessage({id: 'planDetail.maxDaysInFreezer'}),
      inputType: 'number',
    },
    {
      id: 'can_get_800gr_pouches',
      label: intl.formatMessage({id: 'planDetail.canGet800grPouches'}),
      inputType: 'checkbox',
    },
    {
      id: 'can_get_giant_pouches',
      label: intl.formatMessage({id: 'planDetail.canGetGiantPouches'}),
      inputType: 'checkbox',
    },
    {
      readonly: authUser?.email !== 'tania@foodforjoe.es',
      id: 'allow_small_shipments',
      label: intl.formatMessage({id: 'planDetail.allowSmallShipments'}),
      inputType: 'checkbox',
    },
    {
      id: 'ko_reason',
      label: intl.formatMessage({id: 'planDetail.koReason'}),
      inputType: 'select',
      options: selectedPlan?.available_ko_reasons?.map((reason) => ({
        label: reason.name,
        value: reason.code,
      })),
    },
    {
      id: 'shipping_service',
      label: intl.formatMessage({id: 'planDetail.shippingService'}),
      inputType: 'select',
      readonly: true,
      options: shippingServices.map((service) => ({
        label: service.name,
        value: service.id,
      })),
      getter: (aPlan: PlanModel) => aPlan.shipping_service?.id,
    },
    {
      id: 'from_ftk',
      label: intl.formatMessage({id: 'planDetail.fromFrankie'}),
      inputType: 'checkbox',
      readonly: true,
    },
  ]

  function onSubmit(plan: PlanModel) {
    dispatch(planRedux.actions.updateSelectedPlan(plan))
  }

  useEffect(() => {
    switch (status) {
      case 'success_update_selected_plan': {
        setEditing(false)
        toast.success(intl.formatMessage({id: 'planDetail.updatedSuccessfully'}))
        break
      }
      case 'error_update_selected_plan': {
        toast.error(intl.formatMessage({id: 'planDetail.updatedError'}))
        break
      }
    }
  }, [status])

  async function fetchShippingServices() {
    try {
      const {data: services} = await listShippingServices()
      if (services) {
        setShippingServices(services)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!shippingServices.length) {
      fetchShippingServices()
    }
  }, [shippingServices])

  return (
    <section className='card p-10'>
      <GenericForm
        columns={3}
        title={intl.formatMessage({id: 'planDetail.basicInfo'})}
        fields={fields}
        initialState={selectedPlan}
        ctaLabel={intl.formatMessage({id: 'planDetail.ctaLabel'})}
        submitting={status === 'loading_update_selected_plan'}
        toggleEdit={() => setEditing(!editing)}
        onSubmit={onSubmit}
        editing={editing}
        loading={status === 'loading_selected_plan'}
        submittingLabel={intl.formatMessage({id: 'planDetail.loading'})}
      />
    </section>
  )
}
