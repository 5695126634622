import {Modal} from 'react-bootstrap'
import {LeadOrderModel} from '../models/LeadOrderModel'
import {useIntl} from 'react-intl'
import {
  LeadDistributorModel,
  listDistributorShippingAddresses,
} from '../../lead-distributors'
import {ShippingAddressModel} from '../../lead-distributors/models/ShippingAddressModel'
import {useEffect, useState} from 'react'

interface PropsType {
  order: LeadOrderModel | undefined
  distributors: Array<LeadDistributorModel>
  visible: boolean
  onCancel: () => void
  onSaveOrder: (order: LeadOrderModel) => void
}

export default function ModalLeadOrderForm({
  order,
  distributors,
  visible,
  onCancel,
  onSaveOrder,
}: PropsType) {
  const intl = useIntl()
  const [orderState, setOrderState] = useState<LeadOrderModel | undefined>(order)
  const [shippingAddresses, setShippingAddresses] = useState<Array<ShippingAddressModel>>([])

  const fetchAddresses = async (distributorId) => {
    if (distributorId > -1) {
      const {data: newShippingAddresses} = await listDistributorShippingAddresses(distributorId)
      setShippingAddresses(newShippingAddresses)
    } else {
      setShippingAddresses([])
    }
  }

  useEffect(() => {
    setOrderState(order)
  }, [order])

  useEffect(() => {
    if (orderState?.distributor) {
      setShippingAddresses([])
      fetchAddresses(orderState.distributor)
    }
    if (orderState?.distributor === -1 || !orderState?.distributor) {
      setShippingAddresses([])
      setOrderState({
        ...orderState,
        shipping_address: null,
      })
    }
  }, [orderState?.distributor])

  useEffect(() => {
    if (shippingAddresses.length === 1) {
      setOrderState({
        ...orderState,
        shipping_address: shippingAddresses[0].id,
      })
    }
  }, [shippingAddresses])

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSaveOrder(orderState)
  }

  return (
    <Modal show={visible} onClose={onCancel}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            { 
              orderState?.id ? 
                intl.formatMessage({id: 'b2bLeadOrders.editOrder'}) 
                : intl.formatMessage({id: 'b2bLeadOrders.addOrder'}) 
            }
          </h5>
          <button type='button' className='btn-close' onClick={onCancel}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <form onSubmit={onSubmit}>
              <div className='col-12'>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='distributor'>
                    {intl.formatMessage({id: 'b2bLeadOrders.distributor'})}
                  </label>
                  <select
                    id='distributor'
                    className='form-control form-select'
                    value={orderState?.distributor || -1}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        distributor: parseInt(e.target.value, 10),
                      } as LeadOrderModel)
                    }}
                  >
                    <option value={-1}>
                      {intl.formatMessage({id: 'b2bLeadOrders.selectDistributor'})}
                    </option>
                    {distributors.map((distributor) => (
                      <option key={distributor.id} value={distributor.id}>
                        {distributor.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='shipping_address'>
                    {intl.formatMessage({id: 'b2bLeadOrders.shippingAddress'})}
                  </label>
                  <select
                    disabled={!shippingAddresses.length}
                    id='shipping_address'
                    className='form-control form-select'
                    value={orderState?.shipping_address}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        shipping_address: parseInt(e.target.value, 10),
                      } as LeadOrderModel)
                    }}
                  >
                    <option value=''>
                      {intl.formatMessage({id: 'b2bLeadOrders.selectShippingAddress'})}
                    </option>
                    {shippingAddresses.map((shippingAddress) => (
                      <option key={shippingAddress.id} value={shippingAddress.id}>
                        {shippingAddress.name}, {shippingAddress.street}, {shippingAddress.postcode}{' '}
                        {shippingAddress.city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='promise_date'>
                    {intl.formatMessage({id: 'b2bLeadOrders.promiseDate'})}
                  </label>
                  <input
                    id='promise_date'
                    type='date'
                    className='form-control'
                    value={orderState?.promise_date}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        promise_date: e.target.value,
                      } as LeadOrderModel)
                    }}
                  />
                </div>
              </div>
              <div className='form-group mb-4'>
                <label className='fw-bolder mb-1' htmlFor='comments'>
                  {intl.formatMessage({id: 'b2bLeadOrders.comments'})}
                </label>
                <textarea
                  id='comments'
                  className='form-control'
                  value={orderState?.comments}
                  onChange={(e) => {
                    setOrderState({
                      ...orderState,
                      comments: e.target.value,
                    } as LeadOrderModel)
                  }}
                />
              </div>
              <div className='form-group mb-4'>
                <label className='fw-bolder mb-1' htmlFor='kind'>
                  {intl.formatMessage({id: 'b2bLeadOrders.kind'})}
                </label>
                <select
                  id='kind'
                  className='form-control form-select'
                  value={orderState?.kind}
                  onChange={(e) => {
                    setOrderState({
                      ...orderState,
                      kind: e.target.value as 'dog' | 'full',
                    } as LeadOrderModel)
                  }}>
                  <option value='dog'>
                    {intl.formatMessage({id: 'b2bLeadOrders.dogKind'})}
                  </option>
                  <option value='full'>
                    {intl.formatMessage({id: 'b2bLeadOrders.fullKind'})}
                  </option>
                </select>
              </div>
              <button
                disabled={
                  !(orderState?.distributor > -1) ||
                  !(orderState?.shipping_address > -1) ||
                  !orderState?.promise_date
                }
                type='submit'
                className='btn btn-primary ms-auto d-block'
              >
                { 
                  orderState?.id ? 
                    intl.formatMessage({id: 'b2bLeadOrders.updateOrder'}) 
                    :
                  intl.formatMessage({id: 'b2bLeadOrders.createOrder'}) 
                }
              </button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}
