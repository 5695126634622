import {ShippingAddressModel} from '../models/ShippingAddressModel'
import {useIntl} from 'react-intl'
import {FormField, GenericForm} from '../../../components/GenericForm'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import { Modal} from 'react-bootstrap'
import {
  createDistributorShippingAddress,
  listDistributorShippingAddresses,
  updateDistributorShippingAddress,
} from '../services/ShippingAddressCRUD'
import COUNTRIES from '../../../constants/countries'
import COUNTRY_NAMES from '../../../constants/countryNames'
import {listShippingServices} from '../../orders/services/OrderCRUD'

const LOADING_STATUS = 'LOADING_STATUS'
const EDITING_STATUS = 'EDITING_STATUS'
const SAVING_STATUS = 'SAVING_STATUS'
const IDLE_STATUS = 'IDLE_STATUS'

const SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH = [
  16, // BRT
  18 // BRT Frío
];
const LIMITED_NAME_LENGTH = 35;
const DEFAULT_NAME_LENGTH = 50;
const NARVAL_STREET_LENGTH = 150;
const NARVAL_SHIPPING_SERVICE = 17;

export default function LeadDistributorAddresses({id}) {
  const intl = useIntl()
  const [status, setStatus] = useState<string>(IDLE_STATUS)
  const [shippingServices, setShippingServices] = useState([])
  const [shippingAddresses, setShippingAddresses] = useState<Array<ShippingAddressModel>>([])
  const [editableShippingAddresses, setEditableShippingAddresses] = useState<Array<ShippingAddressModel>>([])
  const [shippingAddressStatusses, setShippingAddressStatusses] = useState<Array<string>>([
    LOADING_STATUS,
  ])
  const [visibleShippingAddressModal, setVisibleShippingAddressModal] = useState<boolean>(false)
  const initialShippingAddressFormState = {
    distributor: id,
    name: '',
    street: '',
    postcode: '',
    city: '',
    region: '',
    country: 'ES',
    phone: '',
    comments: '',
    shipping_service: null
  } as ShippingAddressModel;
  const [shippingAddressFormState, setShippingAddressFormState] = useState<ShippingAddressModel>(initialShippingAddressFormState)
  const getShippingAddressesFields = (formState) => [
    {
      id: 'country',
      label: intl.formatMessage({id: 'distributorDetail.countryLabel'}),
      inputType: 'select',
      options: COUNTRIES.map((country) => ({
        value: country,
        label: COUNTRY_NAMES[country],
      })),
    },
    {
      id: 'shipping_service',
      required: true,
      label: intl.formatMessage({id: 'userProfile.shippingService'}),
      inputType: 'select',
      options: shippingServices.map((service) => ({
        value: service.id,
        label: service.name,
      })),
      setter: (state: ShippingAddressModel, value: string): ShippingAddressModel => {
        return {
          ...state,
          shipping_service: parseInt(value, 10),
        }
      }
    },
    {
      id: 'city',
      label: intl.formatMessage({id: 'distributorDetail.cityLabel'}),
      inputType: 'text',
    },
    {
      id: 'postcode',
      label: intl.formatMessage({id: 'distributorDetail.postcodeLabel'}),
      inputType: 'text',
    },
    {
      id: 'phone',
      label: intl.formatMessage({id: 'distributorDetail.phoneLabel'}),
      inputType: 'text',
      maxLength: 14,
    },
    {
      id: 'name',
      label: intl.formatMessage({id: 'distributorDetail.nameLabel'}),
      inputType: 'text',
      maxLength: SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH?.includes(formState?.shipping_service)?
        LIMITED_NAME_LENGTH
        : (
          formState?.shipping_service === NARVAL_SHIPPING_SERVICE ?
            NARVAL_STREET_LENGTH
            : DEFAULT_NAME_LENGTH
        ),
    },
    {
      id: 'street',
      label: intl.formatMessage({id: 'distributorDetail.streetLabel'}),
      inputType: 'text',
      maxLength: SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH?.includes(formState?.shipping_service)?
        LIMITED_NAME_LENGTH
        : (
          formState?.shipping_service === NARVAL_SHIPPING_SERVICE ?
            NARVAL_STREET_LENGTH
            : DEFAULT_NAME_LENGTH
        ),
    },
    {
      columns: 2,
      id: 'comments',
      label: intl.formatMessage({id: 'distributorDetail.commentLabel'}),
      inputType: 'textarea',
    },
  ] as Array<FormField<ShippingAddressModel>>

  function getShippingAddressError(shippingAddressState: ShippingAddressModel): Object {
    const maxLength = SHIPPING_SERVICES_WITH_LIMITED_NAME_LENGTH
      ?.includes(shippingAddressState?.shipping_service) ?
        LIMITED_NAME_LENGTH
        : (
          shippingAddressState?.shipping_service === NARVAL_SHIPPING_SERVICE ?
            NARVAL_STREET_LENGTH
            : DEFAULT_NAME_LENGTH
        );
    const errorObject = {};

    if (shippingAddressState?.name?.length > maxLength) {
      errorObject['name'] = [  
        intl.formatMessage(
          { id: 'distributorDetail.nameTooLong' }, 
          { count: maxLength }
        )
      ];
    }

    if (shippingAddressState?.street?.length > maxLength) {
      errorObject['street'] = [
        intl.formatMessage(
          { id: 'distributorDetail.streetTooLong' }, 
          { count: maxLength }
        )
      ];
    }

    return errorObject;
  }

  async function fetchAddresses(id: number) {
    setStatus(LOADING_STATUS)
    try {
      const {data: newShippingAddresses} = await listDistributorShippingAddresses(id)
      setShippingAddresses(newShippingAddresses)
      setEditableShippingAddresses(newShippingAddresses);
    } catch (e) {
      toast.error(intl.formatMessage({id: 'distributorDetail.fetchBillingAddressesError'}))
    } finally {
      setStatus(IDLE_STATUS)
    }
  }

  useEffect(() => {
    if (id > -1) {
      fetchAddresses(id)
    }
  }, [id])

  function toggleShippingEdit(index) {
    const newStatusses = [...shippingAddressStatusses]
    newStatusses[index] = newStatusses[index] === EDITING_STATUS ? IDLE_STATUS : EDITING_STATUS
    setShippingAddressStatusses(newStatusses)
  }

  async function saveShippingAddress(index: number, address: ShippingAddressModel) {
    const newStatusses = [...shippingAddressStatusses]
    newStatusses[index] = SAVING_STATUS
    setShippingAddressStatusses(newStatusses)

    try {
      await updateDistributorShippingAddress(address)
      await fetchAddresses(id)
      setVisibleShippingAddressModal(false)
      toast.success(intl.formatMessage({id: 'distributorDetail.saveShippingAddressSuccess'}))
    } catch (e) {
      toast.error(intl.formatMessage({id: 'distributorDetail.saveShippingAddressError'}))
    } finally {
      const finalStatusses = [...shippingAddressStatusses]
      finalStatusses[index] = IDLE_STATUS
      setShippingAddressStatusses(finalStatusses)
    }
  }

  function onAddShippingAddress() {
    setVisibleShippingAddressModal(true)
  }

  async function addShippingAddress(address: ShippingAddressModel) {
    try {
      setStatus(SAVING_STATUS)
      await createDistributorShippingAddress(address)
      await fetchAddresses(id)
      setVisibleShippingAddressModal(false)
      toast.success(intl.formatMessage({id: 'distributorDetail.addShippingAddressSuccess'}))
    } catch (e) {
      toast.error(intl.formatMessage({id: 'distributorDetail.addShippingAddressError'}))
    } finally {
      setStatus(IDLE_STATUS)
    }
  }

  async function fetchShippingServices() {
    try {
      const {data: services} = await listShippingServices()
      if (services) {
        setShippingServices(services)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (!shippingServices.length) {
      fetchShippingServices()
    }
  }, [shippingServices])
  
  return (
    <>
      <div className='card p-10'>
        <h1 className='mb-8'>{intl.formatMessage({id: 'distributorDetail.addresses'})}</h1>
        <div className='d-flex mb-8 w-100'>
          <button onClick={onAddShippingAddress} className='py-3 ps-5 fs-6 btn btn-primary'>
            <span className='fas fa-plus me-4'></span>
            {intl.formatMessage({id: 'distributorDetail.addShippingAddress'})}
          </button>
          
        </div>
        <div className=''>
          {
            shippingAddresses?.length > 0 &&
            editableShippingAddresses.length > 0? (
            <section>
              {shippingAddresses.map((shippingAddress: ShippingAddressModel, index) => {
                const editableShippingAddress = editableShippingAddresses[index];

                return <article className='mb-3' key={shippingAddress.id}>
                  <GenericForm
                    columns={2}
                    title={intl.formatMessage({id: 'distributorDetail.shipping'})}
                    fields={getShippingAddressesFields(editableShippingAddress)}
                    initialState={shippingAddress}
                    ctaLabel={intl.formatMessage({id: 'distributorDetail.saveChanges'})}
                    onSubmit={(shippingAddress) => saveShippingAddress(index, shippingAddress)}
                    toggleEdit={() => toggleShippingEdit(index)}
                    editing={[EDITING_STATUS, SAVING_STATUS].includes(
                      shippingAddressStatusses[index]
                    )}
                    submitting={shippingAddressStatusses[index] === SAVING_STATUS}
                    submittingLabel={intl.formatMessage({id: 'distributorDetail.saving'})}
                    state={editableShippingAddress}
                    setState={
                      (state) => setEditableShippingAddresses(
                        editableShippingAddresses.map(
                          (address, i) => (i === index ? state : address))
                        ) as any
                    }
                    error={getShippingAddressError(editableShippingAddress)}
                    disabled={
                      Object.keys(getShippingAddressError(editableShippingAddress)).length > 0
                    }

                  />
                </article>
              })}
            </section>
          ) : (
            <>
              <p>{intl.formatMessage({id: 'distributorDetail.noShippingAddresses'})}</p>
            </>
          )}
        </div>
      </div>
      <Modal
        show={visibleShippingAddressModal}
        centered
        onHide={() => setVisibleShippingAddressModal(false)}
      >
        <Modal.Body>
          <GenericForm
            editable={false}
            columns={2}
            title={intl.formatMessage({id: 'distributorDetail.addShippingAddress'})}
            fields={getShippingAddressesFields(shippingAddressFormState)}
            initialState={initialShippingAddressFormState}
            ctaLabel={intl.formatMessage({id: 'distributorDetail.add'})}
            onSubmit={(shippingAddress) => addShippingAddress(shippingAddress as ShippingAddressModel)}
            toggleEdit={() => {}}
            editing={true}
            submitting={status === 'SAVING_STATUS'}
            submittingLabel={intl.formatMessage({id: 'distributorDetail.saving'})}
            state={shippingAddressFormState as ShippingAddressModel}
            setState={setShippingAddressFormState}
            error={getShippingAddressError(shippingAddressFormState)}
            disabled={
              Object.keys(getShippingAddressError(shippingAddressFormState)).length > 0
            }
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
