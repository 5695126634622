import {MealPlanModel} from './MealPlanModel'
import {SuggestionModel} from './SuggestionModel'
import {PeriodModel} from './PeriodModel'
import {EligibleMealModel} from './EligibleMealModel'
import {ObjectiveModel} from './ObjectiveModel'
import {MealKindModel} from './MealKindModel'
import {ShippingAddressModel} from './ShippingAddressModel'
import {MealPricePerDaySchemeModel} from './MealPricePerDaySchemeModel'
import { MealCustomPlanModel } from './MealCustomPlanModel'
import { MealModel } from './MealModel'
import { ExtraProductModel } from './ExtraProductModel'

export enum PlanStateEnum {
  PROPOSAL = 'proposal',
  ACTIVE = 'active',
  DISCARDED = 'discarded',
  OBSOLETE = 'obsolete',
  NO_PAYMENT_METHOD = 'no-payment-method',
  REACTIVATING = 'reactivating',
  PAYMENT_FAILED = 'payment-failed',
  PAUSED = 'paused',
  DETAINED = 'detained',
  CANCELLED = 'cancelled',
}

export interface PlanModel {
  id: number
  code: string
  state: PlanStateEnum
  daily_calorie_intake: number
  daily_protein_intake: number
  objectives: ObjectiveModel[]
  allergies: any[]
  period: PeriodModel
  eligible_meals: EligibleMealModel[]
  combine: boolean
  created_at: Date
  suggestions: SuggestionModel[]
  meal_plans: MealPlanModel[]
  meal_custom_plans: MealCustomPlanModel[]
  health_issues: any[]
  meal_price_per_day_scheme: MealPricePerDaySchemeModel[]
  meal_price_per_day: number
  checkout_url: string
  kind: string
  pet_id: number
  meal_kind: MealKindModel
  wallet_shipping_address: ShippingAddressModel
  summary: string
  default_meal_price: number
  default_meal_gross_margin: number
  avg_price_per_100gr: number
  available_discounts: [
    {
      code: string
      discount: number
      id: number
      mode: string
      name: string
      state: string
    }
  ]
  current_coupons: [
    {
      expires_at?: string
      discount: {
        code: string
        mode: 'absolute' | 'relative'
        discount: number
        state: 'active' | 'inactive'
        name: string
      }
      id: number
    }
  ]
  available_ko_reasons: Array<{
    code: string
    name: string
    parent?: string
  }>
  ko_reason: string

  regular_instructions_url?: string
  trial_instructions_url?: string
  max_days_in_freezer?: number
  shipping_service?: {
    id: number
    name: string
    priority: number
    gas_comission: number
  }
  can_get_800gr_pouches?: boolean
  can_get_giant_pouches?: boolean
  pricing_model?: 'old' | 'new-2023' | '2025'
  meals_pricing: Array<{
    id: number;
    meal: MealModel;
    size: number;
    pvp: number;
  }>
  extra_products?: Array<ExtraProductModel>
  allow_small_shipments?: boolean
}
