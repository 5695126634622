import {DeliveryNoteLineModel} from './DeliveryNoteLineModel'

export enum DeliveryNoteStateEnum {
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
  NOT_DELIVERED = 'not-delivered',
}

export enum BillingStatusEnum {
  PENDING = 'pending',
  INVOICED = 'invoiced',
}
export interface DeliveryNoteModel {
  id: number
  delivery_note_lines: Array<DeliveryNoteLineModel>
  code: string
  date: string
  state: DeliveryNoteStateEnum
  billing_status: BillingStatusEnum
  edi_file_url?: string
  pdf_file_url?: string
  order: number
  distributor_name: string
  subtotal: number
  total: number
  order_code: string
  order_external_reference?: string
}
