import {OrderLineModel} from './OrderLineModel'

export enum OrderStateEnum {
  PROPOSAL = 'proposal',
  CONFIRMED = 'confirmed',
  LOCKED = 'locked',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  SHIPPED = 'shipped',
  RETURNED = 'returned',
  UNDELIVERED = 'undelivered',
  PARTIALLY_COMPLETED = 'partially_completed',
  SHIPPING_BY_DISTRIBUTOR = 'shipping-by-distributor',
}

export interface OrderModel {
  id: number
  order_lines: Array<OrderLineModel>
  code: string
  budget_at: string
  promise_date: string
  state: OrderStateEnum
  total_wo_taxes: number
  total_products_wo_taxes: number
  total_kg: number
  shipping_fee: number
  total_discounts: number
  subtotal: number
  total_taxes: number
  total_with_taxes: number
  distributor: number
  billing_address: number
  shipping_address: number
  full_address: string
  comments?: string
  is_sample?: boolean
  euro_kg?: number
  external_reference: string
  tracking_link?: string
  discount_mode: 'absolute' | 'relative'
  discount_on: 'order' | 'order_line'
}
